import axios from '@/plugins/axios'
import store from '@/store'

/**
 * Get user
 */
const getUser = async (id) => {
  try {
    const response = await axios.get(`admin/users/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get users
 */
const getUsers = async ({
  // eslint-disable-next-line camelcase
  company_id = null,
  search = null,
  page = 1
}) => {
  try {
    const params = {
      page: page
    }
    if (search) params.search = search
    // eslint-disable-next-line camelcase
    if (company_id) params.company_id = company_id

    const response = await axios.get('admin/users', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Destroy user
 */
const destroyUser = async id => {
  try {
    const response = await axios.post(`admin/users/${id}`, {
      _method: 'DELETE'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Impersonate user
 */
const impersonate = async (id) => {
  try {
    const response = await axios.post(`admin/users/${id}/impersonate`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get current logged user
 *
 * @returns
 */
const me = async function () {
  try {
    const response = await axios.get('admin/users/me')
    const { data } = response

    store.dispatch('users/setUser', { user: data })

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getUser,
  getUsers,
  destroyUser,
  impersonate,
  me
}
