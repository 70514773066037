<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Empresa - {{ company ? company.name : '' }}</h1>
        <!--end::Title-->
      </div>
      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
        <div class="m-0">
          <router-link
            :to="{ name: 'companies.index' }"
            class="btn btn-sm btn-primary"
          >
            Voltar
          </router-link>
        </div>
        <!--end::Filter menu-->
      </div>
      <!--end::Actions-->
      <!--end::Page title-->
    </template>

    <!-- CONTENT -->
    <!--begin::Navbar-->
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-0 pb-0">
        <!--begin::Navs-->
        <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
          <!--begin::Nav item-->
          <li class="nav-item mt-2">
            <a
              class="nav-link text-active-primary ms-0 me-10 py-5"
              :class="{
                active: currentTab === 'details'
              }"
              href="#"
              @click.prevent="changeTab('details')"
            >
              Detalhes
            </a>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item mt-2">
            <a
              class="nav-link text-active-primary ms-0 me-10 py-5"
              :class="{
                active: currentTab === 'users'
              }"
              href="#"
              @click.prevent="changeTab('users')"
            >
              Usuários
            </a>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item mt-2">
            <a
              class="nav-link text-active-primary ms-0 me-10 py-5"
              :class="{
                active: currentTab === 'advertisers'
              }"
              href="#"
              @click.prevent="changeTab('advertisers')"
            >
              Anunciantes
            </a>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item mt-2">
            <a
              class="nav-link text-active-primary ms-0 me-10 py-5"
              :class="{
                active: currentTab === 'dashboards'
              }"
              href="#"
              @click.prevent="changeTab('dashboards')"
            >
              Dashboards
            </a>
          </li>
          <!--end::Nav item-->

          <!--begin::Nav item-->
          <li class="nav-item mt-2">
            <a
              class="nav-link text-active-primary ms-0 me-10 py-5"
              :class="{
                active: currentTab === 'wallets'
              }"
              href="#"
              @click.prevent="changeTab('wallets')"
            >
              Histórico de transações
            </a>
          </li>
          <!--end::Nav item-->
        </ul>
        <!--begin::Navs-->
      </div>
    </div>
    <!--end::Navbar-->

    <!-- DETALHES TAB -->
    <!--begin::details View-->
    <div class="card mb-5 mb-xl-10" v-show="currentTab === 'details'">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bold m-0">Detalhes</h3>
        </div>
        <!--end::Card title-->
        <!--begin::Action-->
        <!--end::Action-->
      </div>
      <!--begin::Card header-->
      <!--begin::Card body-->
      <div class="card-body p-9">
        <template v-if="companyLoader">
          <div class="py-5">
            <Loader size="big" />
          </div>
        </template>
        <template v-else>
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">ID</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ company.id }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Razão social</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ company.name }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Nome fantasia</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ company.fantasy_name }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Tipo</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ company.type == 'AGENCY' ? 'AGÊNCIA' : 'ANUNCIANTE' }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">CNPJ</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">
                <template v-if="company.document.length === 11">
                    {{ company.document | VMask('###.###.###-##') }}
                  </template>
                  <template v-else>
                    {{ company.document | VMask('##.###.###/####-##') }}
                  </template>
                </span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Endereço</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6" v-if="company.address">
                {{`${company.address.street}, ${company.address.number}${company.address.complement ? ' - ' + company.address.complement : ''} - ${company.address.city}, ${company.address.state}`}} - CEP: {{ company.address.zipcode | VMask('#####-###') }}
              </span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Data de criação</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ company.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Data de última atualização</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ company.updated_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!-- IMPERSONATE -->
          <div class="row mb-7">
            <h2 class="m-0 text-gray-900 flex-grow-1">Impersonate</h2>
            <div class="mt-5">
              <ImpersonateButton
                :userId="company.user_impersonate_id"
              />
            </div>
          </div> <!-- END IMPERSONATE -->
        </template>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::details View--> <!-- END DETAILS TAB -->

    <!-- USERS TAB -->
    <!--begin::details View-->
    <div class="card mb-5 mb-xl-10" v-show="currentTab === 'users'">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bold m-0">Usuários</h3>
        </div>
        <!--end::Card title-->
        <!--begin::Action-->
        <!--end::Action-->
      </div>
      <!--begin::Card header-->
      <!--begin::Card body-->
      <div class="card-body p-9" v-if="company">
        <UserListTable
          :companyId="company.id"
        />
      </div>
      <!--end::Card body-->
    </div>
    <!--end::details View--> <!-- END USERS TAB -->

    <!-- ADVERTISERS TAB -->
    <!--begin::details View-->
    <div class="card mb-5 mb-xl-10" v-show="currentTab === 'advertisers'">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bold m-0">Anunciantes</h3>
        </div>
        <!--end::Card title-->
        <!--begin::Action-->
        <!--end::Action-->
      </div>
      <!--begin::Card header-->
      <!--begin::Card body-->
      <div class="card-body p-9" v-if="company">
        <AdvertiserListTable
          :companyId="company.id"
        />
      </div>
      <!--end::Card body-->
    </div>
    <!--end::details View--> <!-- END ADVERTISERS TAB -->

    <!-- DASHBOARDS TAB -->
    <!--begin::details View-->
    <div class="card mb-5 mb-xl-10" v-show="currentTab === 'dashboards'">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer align-items-center">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bold m-0">Dashboards</h3>
        </div>
        <!--end::Card title-->
        <!--begin::Action-->
        <!--end::Action-->
      </div>
      <!--begin::Card header-->
      <!--begin::Card body-->
      <div class="card-body p-9" v-if="company">
        <DashboardListTable
          :companyId="company.id"
        />
      </div>
      <!--end::Card body-->
    </div>
    <!--end::details View--> <!-- END DASHBOARDS TAB -->
        <!-- WALLETS TAB -->
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10" v-show="currentTab === 'wallets'">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Histórico de transações</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9" v-if="company">
          <WalletListTable
            :companyId="company.id"
          />
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View--> <!-- END WALLETS TAB -->
 </MainLayout>
</template>

<script>

import CompaniesService from '@/modules/companies/services/companies-service'
import { mask } from 'vue-the-mask'

import AdvertiserListTable from '@/components/advertisers/AdvertiserListTable'
import DashboardListTable from '@/components/dashboards/DashboardListTable'
import ImpersonateButton from '@/components/users/ImpersonateButton'
import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'
import UserListTable from '@/components/users/UserListTable'
import WalletListTable from '@/components/wallets/WalletListTable'

export default {
  directives: { mask },
  components: {
    ImpersonateButton,
    Loader,
    MainLayout,
    AdvertiserListTable,
    DashboardListTable,
    UserListTable,
    WalletListTable
  },
  metaInfo () {
    return {
      title: this.company ? this.company.name : ''
    }
  },
  data () {
    return {
      company: '',
      companyLoader: false,
      currentTab: 'details'
    }
  },
  created () {
    this.getCompany()
  },
  computed: {
    /**
     * Current tab title
     */
    tabTitle () {
      switch (this.currentTab) {
        case 'users':
          return 'Usuários'
        default:
          return 'Detalhes'
      }
    }
  },
  methods: {
    /**
     * Change current tab
     */
    changeTab (tab) {
      this.currentTab = tab
    },
    /**
     * Get company
     */
    async getCompany () {
      this.companyLoader = true
      try {
        const company = await CompaniesService.getCompany(this.$route.params.id)
        this.company = company
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.companyLoader = false
    }
  }
}
</script>
