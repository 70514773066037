<template>
  <div class="creative-list-table">
    <div v-if="loader && localCreatives.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else>
      <div class="table-responsive">
        <!--begin::Table-->
        <table class="table align-middle table-row-dashed fs-6 gy-5">
          <!--begin::Table head-->
          <thead>
            <!--begin::Table row-->
            <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
              <th class="min-w-100px">Nome</th>
              <th class="min-w-70px">Arquivo</th>
              <th class="min-w-100px">Formato</th>
              <th class="min-w-70px">Tamanho</th>
              <th class="min-w-70px">Status</th>
            </tr>
            <!--end::Table row-->
          </thead>
          <!--end::Table head-->
          <!--begin::Table body-->
          <tbody class="fw-semibold text-gray-600">
            <!--begin::Table row-->
            <tr v-for="(creative, index) in localCreatives" :key="index">
              <td>
                <span>{{ creative.name }}</span>
              </td>
              <td>
                <span>
                  <a :href="creative.file" target="_blank">
                    <img :src="creative.file" alt="" width="100" />
                  </a>
                </span>
              </td>
              <td>
                <span>{{ creative.format | formatCreativeFormat }}</span>
              </td>
              <td>
                <span>{{ creative.layout }}</span>
              </td>
              <td>
                <span>{{ creative.status | formatStatus }}</span>
              </td>
            </tr>
            <!--end::Table row-->
          </tbody>
          <!--end::Table body-->
        </table>
      </div>
      <!--end::Table-->
      <div class="text-center mt-4">
        <Button
          class="btn-primary"
          :loading="loader"
          @click="loadMore"
          v-if="isLoadMoreVisible"
        >
          Carregar mais
        </Button>
      </div>
    </template>
  </div>
</template>

<script>

import CreativesService from '@/modules/creatives/services/creatives-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'CreativeListTable',
  props: ['advertiserId', 'creatives'],
  components: {
    Button,
    Loader
  },
  data () {
    return {
      localCreatives: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true
    }
  },
  created () {
    if (this.creatives) {
      this.localCreatives = this.creatives
      this.isLoadMoreVisible = false
    } else {
      this.getCreatives()
    }
  },
  methods: {
    /**
     * Get creatives
     */
    async getCreatives () {
      if (!this.advertiserId) return false

      this.loader = true
      try {
        const creatives = await CreativesService.getCreatives({
          advertiser_id: this.advertiserId,
          page: this.page
        })
        this.localCreatives = [...this.localCreatives, ...creatives.data]
        this.isLoadMoreVisible = creatives.meta.current_page !== creatives.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getCreatives()
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localCreatives = []
      this.getCreatives()
    }
  }
}
</script>
