<template>
  <div class="user-list-table">
    <div v-if="loader && localUsers.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else>
      <div class="table-responsive">
        <!--begin::Table-->
        <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
          <!--begin::Table head-->
          <thead>
            <!--begin::Table row-->
            <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
              <th class="min-w-70px">Nome</th>
              <th class="min-w-100px">Email</th>
              <th class="min-w-100px">Tipo</th>
              <th class="min-w-70px">Data de cadastro</th>
              <th class="min-w-70px">Ativo</th>
              <th class="min-w-70px">Ações</th>
            </tr>
            <!--end::Table row-->
          </thead>
          <!--end::Table head-->
          <!--begin::Table body-->
          <tbody class="fw-semibold text-gray-600">
            <!--begin::Table row-->
            <tr v-for="(user, index) in localUsers" :key="index">
              <td>
                <span>{{ user.name }}</span>
              </td>
              <td>
                <span>{{ user.email }}</span>
              </td>
              <td>
                <span>{{ user.role.name }}</span>
              </td>
              <td>
                <span>{{ user.created_at | formatDate }}</span>
              </td>
              <td>
                <span>{{ user.is_active ? 'Sim' : 'Não' }}</span>
              </td>
              <td>
                <div class="d-flex gap-2">
                  <router-link
                    :to="{ name: 'users.show', params: { id: user.id } }"
                    class="btn btn-sm btn-primary"
                    v-b-tooltip.hover title="Visualizar"
                  >
                    <i class="fas fa-eye px-0"></i>
                  </router-link>
                  <span
                    :id="`destroy-user-${user.id}-tooltip`"
                    v-if="user.is_active"
                  >
                    <Button
                      type="button"
                      :class="{
                        'btn btn-sm btn-danger' : true,
                        'disabled' : !canDestroyUser(user)
                      }"
                      v-b-modal="`user-destroy-modal-${user.id}`"
                    >
                      <i class="fa-solid fa-xmark p-0"></i>
                    </Button>
                  </span>
                  <b-tooltip :target="`destroy-user-${user.id}-tooltip`" triggers="hover" v-if="!canDestroyUser(user)">
                    {{ getDestroyButtonTooltip(user) }}
                  </b-tooltip>
                  <DestroyUserModal
                    :user="user"
                    @destroyed="reset"
                  />
                </div>
              </td>
            </tr>
            <!--end::Table row-->
          </tbody>
          <!--end::Table body-->
        </table>
      </div>
      <!--end::Table-->
      <div class="text-center mt-4">
        <Button
          class="btn-primary"
          :loading="loader"
          @click="loadMore"
          v-if="isLoadMoreVisible"
        >
          Carregar mais
        </Button>
      </div>
    </template>
  </div>
</template>

<script>

import UsersService from '@/modules/users/services/users-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import DestroyUserModal from '@/components/users/DestroyUserModal'

export default {
  name: 'UserListTable',
  props: ['companyId', 'users'],
  components: {
    Button,
    Loader,
    DestroyUserModal
  },
  data () {
    return {
      localUsers: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true
    }
  },
  created () {
    if (this.users) {
      this.localUsers = this.users
      this.isLoadMoreVisible = false
    } else {
      this.getUsers()
    }
  },
  watch: {
    reload (newValue) {
      if (newValue) this.reset()
    }
  },
  computed: {
    /**
     * Check if there are two or more managers
    */
    hasTwoUserManagers () {
      const userManagers = this.localUsers.filter(user => {
        return user.role.slug === 'user-manager' && user.is_active === true
      })
      return userManagers.length >= 2
    }
  },
  methods: {
    /**
     * Check if can destroy user
    */
    canDestroyUser (user) {
      if (user.role.slug === 'user-manager') return this.hasTwoUserManagers
      return this.localUsers.length > 1
    },
    /**
     * Get the tooltip of the button to destroy the user
    */
    getDestroyButtonTooltip (user) {
      if (this.localUsers.length === 1) return 'Não é possível desativar usuários quando há apenas um usuário cadastrado'

      if (!this.canDestroyUser(user)) return 'Não é possível desativar usuários administradores quando há apenas um usuário desse tipo'

      return 'Desativar'
    },
    /**
     * Get users
     */
    async getUsers () {
      if (!this.companyId) return false

      this.loader = true
      try {
        const users = await UsersService.getUsers({
          company_id: this.companyId,
          page: this.page
        })
        this.localUsers = [...this.localUsers, ...users.data]
        this.isLoadMoreVisible = users.meta.current_page !== users.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getUsers()
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localUsers = []
      this.getUsers()
    }
  }
}
</script>
